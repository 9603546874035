<template>
  <onboarding-layout  back-route-name="OnboardingPetType">
    <h1 class="h2">
      {{ heading }}
    </h1>

    <p class="color-text">
      {{ description }}
    </p>

    <form action="" method="POST" @submit.prevent="onSubmit">
      <Errors :errors="errors" />

      <div class="name-container">
        <div class="mb-2">
          <label class="visually-hidden" for="petName">
            {{ label }}
          </label>
          <input
            class="form-control form-control--center-placeholder"
            type="text"
            name="petName"
            id="petName"
            :placeholder="label"
            v-model="formName"
            required
          >
        </div>

        <Button button-type="submit" classes="btn--full-width">
          Naar volgende stap
        </Button>
      </div>
    </form>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';
import Button from '../../components/Button.vue';

export default {
  components: {
    Button,
    Errors,
    OnboardingLayout,
  },

  data() {
    return {
      errors: [],
      formName: this.$store.state.onboarding.petName,
    };
  },

  created() {
    this.$store.commit('resetLayout', {
      showCat: this.petType === 'cat',
      showDog: this.petType === 'dog',
      showCta: false,
      progress: 33.333,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '2-pet-name',
    });
  },

  methods: {
    onSubmit() {
      this.errors = [];

      if (!this.formName || this.formName.length < 2) {
        this.errors.push('Geef een naam op voor je huisdier');
        return;
      }

      this.$store.commit('setPetName', this.formName);

      this.$router.push({ name: 'OnboardingPetBirthdate' });
    },
  },

  computed: {
    petType() {
      return this.$store.state.onboarding.petType;
    },

    heading() {
      if (this.petType === 'cat') {
        return 'Wat leuk! Hoe heet je kitten?';
      }

      return 'Wat leuk! Hoe heet je puppy?';
    },

    label() {
      if (this.petType === 'cat') {
        return 'Vul hier de naam van je kitten in';
      }

      return 'Vul hier de naam van je puppy in';
    },

    description() {
      if (this.petType === 'cat') {
        return 'Je kunt de naam van je kitten later nog aanpassen in je account.';
      }

      return 'Je kunt de naam van je puppy later nog aanpassen in je account.';
    },
  },
};
</script>

<style>
.name-container {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
